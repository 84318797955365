export default {
  'nav.account': 'Shaxsiy markaz',
  'nav.language': 'Soddalashtirilgan xitoycha',
  'signup.success': 'Muvaffaqiyatli topshirildi',

  // xato
  'contact.empty': "Kontakt ma'lumotlari bo'sh bo'lishi mumkin emas",
  'phone.country.empty': "Telefon prefiksi bo'sh bo'lishi mumkin emas",
  'phone.empty': "Telefon bo'sh bo'lishi mumkin emas",
  'email.valid': "Iltimos, to'g'ri elektron pochta manzilini kiriting",

  // asos
  'base.success': 'muvaffaqiyat',
  'base.save': 'saqlash',
  'base.slide': 'Tasdiqlash uchun slayderni torting',
  'base.send.code': 'Tasdiqlash kodini yuborish',
  'base.send.time': '({time}) Tasdiqlash kodini qayta yuborish',
  'base.send.success': 'Yuborish muvaffaqiyatli',
  'base.view.more': "Ko'proq ko'rib chiqish",
  'base.Language': 'Til',
  'base.previous': 'Oldingi sahifa',
  'base.next': 'Keyingi sahifa',
  'base.total.page': 'Jami {page} sahifa',
  'base.go.to.page': "Sahifaga o'tish",
  'base.go': 'Borish',
  'base.followers': 'Kuzatuvchilar',
  'base.follow': 'Kuzatish',
  'base.following': 'allaqachon kuzatishda',
  'base.visit.store': "Do'konni ko'rib chiqish",
  'base.contact': "Biz bilan bog'laning",
  'base.delivery': 'Taxminiy yetkazib berish muddati: 1-7 kun',
  'base.search': 'Qidiruv',
  'base.search.text': "Qidirish uchun kalit so'zlarni kiriting",
  'base.server': "Onlayn mijozlarga xizmat ko'rsatish",

  //indeks
  'text.categories.title': 'Mahsulot toifalari',
  'text.welcome': 'Xarid qilishga xush kelibsiz!',
  'text.wishlist': "Like ro'yxati",
  'text.account': 'Shaxsiy markaz',
  'text.orders': 'Buyurtmalar',
  'text.messages': 'Xabar',
  'text.suggest.title': 'Eksklyuziv taklif',
  'text.suggest.content': "Faqat yangi Global Supreme a'zolari uchun!",
  'text.super.deals': 'Ajoyib narxlarda eng yaxshi mahsulotlar.',

  //Ro'yxatdan o'tish
  'signup.register': "Ro'yxatdan o'tish",
  'signup.signin': 'Kirish',
  'signup.store.signin': 'Biznesga kirish',
  'signup.sign.out': 'Chiqish',
  'signup.email': 'Elektron pochta manzili',
  'signup.password': 'parol',
  'signup.qr.password': 'Parolni tasdiqlang',
  'signup.confirm.password': 'Parolni tasdiqlang',
  'signup.forgot.password': 'Parolni unutdingiz',
  'signup.invitation': 'taklif kodi',
  'signup.char': 'Belgi',
  'signup.contains': "Raqamlar, harflar yoki belgilarni o'z ichiga oladi",
  'signup.qr.contains': 'Ikkita parol bir-biriga mos kelmaydi',
  'signup.create.account': 'Hisob yaratish',
  'signup.agree': 'Hisob yaratish va rozilik bildirish',
  'signup.member.agreement': "A'zolik shartnomasi",
  'signup.and': 'bilan',
  'signup.privacy.policy': 'Maxfiylik siyosati',
  'signup.email.code': 'Elektron pochtani tekshirish',
  'signup.last.step': 'Oxirgi qadam',
  'signup.send.email': 'Iltimos, {email} manziliga yuborilgan 4 xonali kodni kiriting',
  'signup.modify.email': "Email manzilini o'zgartirish",
  'signup.verify.email': 'Elektron pochtani tekshirish',
  'signup.have.store': "Savdogar hisobiga ega bo'ling",
  'signup.goto.signin': 'Savdogar login',
  'signup.no.store': "Savdogar hisobi yo'q",
  'signup.goto.store': "Ro'yxatdan o'tgan savdogar",
  'signup.next': 'Keyingi',
  'signup.your.email': 'sizning elektron pochtangiz',
  'signup.code.text': 'Tasdiqlash kodi',
  'signup.submit.signup': "Hozir ro'yxatdan o'ting",
  'signup.smrz': 'Haqiqiy ism autentifikatsiyasi',
  'signup.derb': '2-qadam',
  'signup.qsrxm': 'Iltimos, sertifikatdagi ismni kiriting',
  'signup.qsrhm': 'Identifikatsiya raqamini kiriting',

  // unutgan
  'forgot.title': 'Parolni tiklash',
  'forgot.btn.check': 'Elektron pochtani tekshirish',
  'forgot.reset.now': 'Endi tiklash',

  //do'kon
  'store.info.open': "Ochiq do'kon {y} hafta",

  // altbilgi
  'footer.great.value': 'Ajoyib qiymat',
  'footer.great.value.desc': 'Biz 100 milliondan ortiq mahsulot uchun raqobatbardosh narxlarni taklif qilamiz.',
  'footer.shopping': 'Global xarid',
  'footer.shopping.desc': 'Biz 200 dan ortiq mamlakat va mintaqalarga yetkazib beramiz va veb-saytimiz 7 tilda mavjud.',
  'footer.safe.payment': "Xavfsiz to'lov",
  'footer.safe.payment.desc': "Dunyodagi eng mashhur va xavfsiz to'lov usuli bilan to'lang.",
  'footer.shop.with.confidence': 'Ishonch bilan xarid qiling',
  'footer.shop.with.confidence.desc': 'Bizning xaridorlarni himoya qilish siyosatimiz butun xarid jarayonini qamrab oladi.',
  'footer.help.center': 'Yordam markazi',
  'footer.help.center.desc': 'Bemalol xarid qilish tajribasini yaratish uchun 24/7 yordam.',
  'footer.terms.conditions': 'Shartlar va shartlar',
  'footer.return.policy': 'Qaytish siyosati',
  'footer.support.policy': "Qo'llab-quvvatlash siyosati",
  'footer.privacy.policy': 'Maxfiylik siyosati',
  'footer.be.seller': "Savdogar bo'l",
  'footer.apply.now': 'Hozir murojaat qiling',
  'footer.stay.connected': 'Aloqada qoling',

  'footer.about.us': 'Biz haqimizda',
  'footer.about.company': 'Kompaniya profili',
  'footer.about.video': 'Video taqdimoti',
  'footer.contact': "Aloqa ma'lumotlari",

  'footer.my.account': 'Mening hisobim',
  'footer.my.logout': 'Chiqish',
  'footer.my.order': 'Buyurtma tarixi',
  'footer.my.wish': "Mening tilaklarim ro'yxati",
  'footer.my.join': "A'zo bo'lish hamkori",
  'footer.email': 'elektron pochta',
  'footer.gfemail': 'Rasmiy elektron pochta',
  'footer.fwemail': 'Xizmat elektron pochtasi',
  'footer.address': 'manzil',

  'apply.success': 'Ilova muvaffaqiyatli',
  'apply.success.desc': "Ilova muvaffaqiyatli bo'ldi, login hisobiga o'ting",

  // kategoriyalar
  'category.title': 'Aloqador toifalar',
  'category.all': 'Barcha toifalar',

  // tafsilot
  'detail.store.home': "do'kon bosh sahifasi",
  'detail.sale.items': "elementlar ro'yxati",
  'detail.recommend': 'tavsiya etiladi',
  'detail.orders': 'Sotish',
  'detail.quantity': 'Miqdor',
  'detail.pieces.available': 'Mavjud',
  'detail.delivery': 'etkazib berish',
  'detail.free.shipping': 'Bepul yetkazib berish',
  'detail.estimated.delivery': 'Taxminiy yetib kelish',
  'detail.days': 'kunlar',
  'detail.buy.now': 'Sotib olish',
  'detail.add.to.cart': "Savatga qo'shish",
  'detail.buyer.protection': 'Xaridor himoyasi',
  'detail.money.guarantee': 'Pulni qaytarish kafolati',
  'detail.refund.desc': "Agar mahsulot tavsiflanganidek bo'lmasa yoki kelmasa, to'lovni to'liq qaytarib oling",
  'detail.description': 'Mahsulot tavsifi',
  'detail.customer.reviews': 'Mijoz sharhlari',
  'detail.specifications': 'Mahsulot spetsifikatsiyalari',
  'detail.top.selling.products': "Eng ko'p sotiladigan mahsulotlar",
  'detail.recommended.for.siz': 'Siz uchun tavsiya etilgan',
  'detail.sold': 'Sotish',
  'detail.receipt': 'Qabulni tasdiqlang',
  'detail.receipt.title': 'Buyurtma olinganligi tasdiqlansinmi? ',
  'detail.receipt.content': "Tasdiqlangandan so'ng, tranzaksiya buyurtmasi yakunlandi",
  'detail.comment': 'Izoh',
  'detail.refund.title': "To'lovni qaytarish uchun arizani tasdiqlash",
  'detail.refund.content': 'Tasdiqlangandan keyin buyurtma puli qaytariladi',
  'detail.sqtk': "To'lovni qaytarish uchun ariza",

  // tasdiqlang
  'confirm.shipping.address': 'qabul qiluvchi manzil',
  'confirm.change': "o'zgartirish",
  'confirm.payment.methods': "To'lov usullari",
  'confirm.summary': 'Hisoblash',
  'confirm.total.item.costs': 'Jami elementlar',
  'confirm.total': 'Jami',
  'confirm.checkout': "To'lovga o'tish",
  'confirm.place.order': 'Buyurtmani hozir bering',
  'confirm.pay.now': "Hozir to'lash",
  'confirm.order.desc': "'Buyurtma berish' tugmasini bosgandan so'ng men o'qib chiqqanimni va tasdiqlaganimni tasdiqlayman",
  'confirm.order.policy': 'Barcha shartlar va qoidalar',
  'confirm.payment': "Global xarid ma'lumotlaringiz va to'lovlaringizni xavfsiz saqlaydi",

  //manzil
  'address.title': 'qabul qiluvchi manzil',
  'address.default': 'Birlamchi',
  'address.edit': 'tahrirlash',
  'address.delete': "O'chirish",
  'address.new': 'Yangi manzil yaratish',
  'address.contact': 'Aloqa',
  'address.address': 'manzil',
  'address.phone': 'telefon raqami',
  'address.set.default': "Standartni o'rnatish",
  'address.confirm': 'Tasdiqlash',
  'address.cancel': 'Bekor qilish',
  'address.del.title': "Manzilni o'chirishni tasdiqlaysizmi?",
  'address.del.content': "Ushbu yuk manzilini o'chirib tashlamoqchimisiz?",

  'payment.method': "To'lov usuli",

  // Xarid savati
  'shopping.title': 'Savatcha',
  'shopping.back.buy': 'Maxsus taklif xaridiga qaytish',
  'shopping.start.shopping': 'Xarid qilishni boshlang',

  // a'zo
  'member.account': 'Shaxsiy markaz',
  'member.overview': "umumiy ko'rinish",
  'member.orders': 'Buyurtmalar',
  'member.payment': 'Mening hamyonim',
  'member.address': 'Etkazib berish manzili',
  'member.wishlist': "like ro'yxati",
  'member.followlist': "Kuzatilish ro'yxati",
  'member.message.center': 'Xabarlar markazi',
  'member.setting': 'Shaxsiy sozlamalar',
  'member.shop.info': "Do'kon haqida ma'lumot",
  'member.shop.setting': "Do'kon sozlamalari",
  'member.order.notify': 'Yangi buyurtma bildirishnomasi',
  'member.order.tips': 'Savdogar hisobiga elektron pochta xabarini yuborish',

  // a'zo.buyurtma
  'member.order.title': 'Mening buyurtmam',
  'member.order.view.all': "Hammasini ko'rish",
  'member.order.all': 'barchasi',
  'member.order.unpaid': "To'lanmagan",
  'member.order.paid': "to'langan",
  'member.order.procurement': 'sotib olinadi',
  'member.order.seller.paid': 'Sotib olindi',
  'member.order.processing': 'kutishda',
  'member.order.shipped': 'Yetkazib berish',
  'member.order.completed': 'Bajarildi',
  'member.order.refund': "To'lov qaytarildi",
  'member.order.all.time': 'barchasi',
  'member.order.empty': "Hozircha buyurtma yo'q",
  'member.order.date': 'sana',
  'member.order.purchase.date': 'Sotib olish',
  'member.order.cpoy': 'Nusxa olish',
  'member.order.id': 'Buyurtma',
  'member.order.detail': 'Buyurtma tafsilotlari',
  'member.order.logistics': "Logistika ma'lumotlari",

  // a'zo.to'lov
  'member.payment.title': 'Mening hamyonim',
  'member.wallet.balance': 'hamyon balansi',
  'member.crypto.recharge': "To'ldirish",
  'member.crypto.withdrawal': "O'chirish",
  'member.crypto.bank': 'Bank kartasi',
  'member.wallet.record': 'Tanga depozit rekordi',
  'member.bankcard.record': 'Bank kartasi rekordi',
  'member.withdrawal.record': "O'chirish yozuvi",
  'member.income.record': 'Daromad rekordi',
  'member.transaction.record': 'Tranzaksiya yozuvi',
  'member.wallet.freeze': "Muzlatilgan mablag'lar",
  'member.wallet.profit': 'Taxminiy foyda',

  // zaryadlash
  'recharge.currency': 'valyuta',
  'recharge.protocol': 'Protokol',
  'recharge.qrcode': 'QR kod',
  'recharge.address': 'hamyon manzili',
  'recharge.copy': 'Manzilni nusxalash',
  'recharge.ok.text': 'Tasdiqlash',
  'recharge.cancel.text': 'Bekor qilish',

  //bank
  'bank.recharge.title': "Bank kartasini to'ldirish",
  'bank.title': 'Bank nomi',
  'bank.name': "To'lovchining ismi",
  'bank.account': "To'lov hisob raqami",
  'bank.routing': 'Bank marshrut raqami',
  'bank.code': 'Bank kodi',
  'bank.bankname': 'Bank nomi',
  'bank.bankaddress': 'Bank manzili',
  'bank.ok.text': 'Keyingi qadam',
  'bank.ok.prev': 'Oldingi qadam',
  'bank.submit': 'Hozir topshiring',
  'bank.amount': "To'ldirish summasi",
  'bank.amount.desc': "Iltimos, to'ldirish miqdorini kiriting",
  'bank.type': 'turi',
  'bank.usd': 'USD',
  'bank.eur': 'Evro',
  'bank.receipt.number': 'kvitansiya raqami',
  'bank.receipt.number.desc': 'Tranzaksiya kvitansiyasi raqamini kiriting',
  'bank.credential.picture': "Hisob ma'lumotlari rasmi",
  'bank.credential.picture.desc': 'Iltimos, sertifikat rasmini yuklang',
  'bank.remark': 'Tavsif',
  'bank.upload': 'Rasmlarni yuklash',
  'bank.text.title': 'Eslatmalar',
  'bank.text.t1': "1. O'tkazish uchun hech qanday eslatma talab qilinmaydi, uni bo'sh qoldiring",
  'bank.text.t2': "2. Gonkongni Gonkong hisobi sifatida tanlang, Xitoyni qo'shmang",
  'bank.text.t3': "3. Amerika Qo'shma Shtatlariga pul o'tkazmalari bank ish vaqtida 15:00 dan oldin amalga oshirilishi mumkin.",
  'bank.text.t4': "4. To'lov muddati T+1, Yevropada esa oxirgi to'lov T+3",
  'bank.text.t5': "5. Agar hisob yopilgan bo'lsa, pul o'tkazmasini tasdiqlash uchun platformaga xabar bering.",
  'bank.text.t6': "6. Bank kartalari orqali pul mablag'larini kiritish uchun barcha mijozlarga xizmat ko'rsatish ma'lumotlari ustunlik qiladi.",

  //bank ro'yxati
  'bank.list.title': 'Bank nomi',
  'bank.list.code': 'Bank kodi',
  'bank.list.amount': "O'tkazma summasi",
  'bank.list.number': 'Kvitansiya raqami',
  'bank.list.img': 'Voucher rasmi',
  'bank.list.status': 'Holat',
  'bank.list.time': 'vaqt',
  'bank.list.status1': "Ko'rib chiqilmoqda",
  'bank.list.status2': "O'tdi",
  'bank.list.status3': 'Rad etilgan',

  // Olib tashlash
  'withdrawal.address.desc': 'Iltimos, hamyoningiz manzilini kiriting!',
  'withdrawal.number': 'Miqdor',
  'withdrawal.real.number': 'Haqiqiy kelish',
  'withdrawal.number.desc': 'Iltimos, yechib olish miqdorini kiriting!',
  'withdrawal.btn.all': 'Hammasi',
  'withdrawal.balance': 'Balans',
  'withdrawal.commission': 'komissiya',
  'withdrawal.actual.amount': 'Olib tashlash',
  'withdrawal.notice': 'maslahat',
  'withdrawal.notice.text': "Pul o'tkazishdan oldin, to'lov manzili ma'lumotlari to'g'ri ekanligini tasdiqlang, aktivlar o'tkazilgandan keyin ularni qaytarib bo'lmaydi.",
  'withdrawal.notice.content': '{name}({cp_name}) Komissiya: joriy bozor qiymati {fee_rate}%/qalam, minimal standart: {fee_min} {name}/qalam',
  'withdrawal.submit': 'Yuborish',
  'withdrawal.choice': 'Kriptovalyutani tanlang',
  'withdrawal.yzm': 'Tasdiqlash kodi',
  'withdrawal.fs': 'yuborish',
  'withdrawal.qsryzm': 'Iltimos, elektron pochta tasdiqlash kodini kiriting',

  // qabul qilish
  'recive.method': 'Protokol',
  'recive.amount': 'Miqdor',
  'recive.address': 'manzil',
  'recive.date': 'vaqt',
  'recive.status': 'status',
  'recive.create.at': 'Tranzaktsiya vaqti',
  'recive.type': 'turi',
  'recive.befor': 'Tranzaksiyadan oldin',
  'recive.balance': 'Balans',
  'recive.freeze': 'muzlatish',
  'recive.review': "ko'rib chiqilmoqda",
  'recive.success': "O'tdi",
  'recive.reject': 'Rad etilgan',

  // reklama
  'advertise.title': 'Promo-markaz',
  'advertise.shop.title': "Do'kon reklamasi",
  'advertise.shop.status': 'Holat',
  'advertise.shop.expired': 'Muddati tugagan',
  'advertise.shop.promotion': "Targ'ib qilish",
  'advertise.shop.expire.date': 'Yaroqlilik muddati',
  'advertise.shop.renew': 'Reklamani yangilash',
  'advertise.shop.payable': "To'lanadigan summa",
  'advertise.shop.explanation': "Rag'batlantirish bo'yicha tushuntirish",
  'advertise.shop.text': "Platformadagi reklama targ'ibotida ishtirok eting, do'kon ta'sirini oshiring va tranzaksiya buyurtmalari hajmini rag'batlantiring",
  'advertise.shop.paynow': 'Endi yangilang',
  'advertise.shop.modal.title': "To'lovni tasdiqlang",
  'advertise.shop.modal.desc': "Aksiya to'lovi to'langanligini tasdiqlang",
  'advertise.shop.modal.btn': "To'lovni tasdiqlang",

  // Istaklar ro'yxati
  'wishlist.title': "Like ro'yxati",
  'wishlist.delete': "O'chirish",
  'wishlist.orders': 'Sotish',

  //Kuzatilish ro'yxati
  'followlist.title': "Kuzatilish ro'yxati",
  'followlist.delete': "O'chirish",
  'followlist.follow': 'Kuzatish',

  // do'kon
  'store.dashboard': 'Boshqaruv paneli',
  'store.products': 'mahsulotlar',
  'store.products.list': "Mahsulotlar ro'yxati",
  'store.products.reviews': 'Mahsulotga javob',
  'store.orders': 'Buyurtmalar',
  'store.wallet': 'hamyon',
  'store.message': 'Xabarlar markazi',
  'store.setting': 'sozlash',
  'store.order.total.profit': 'Tahmin qilingan umumiy operatsion daromad',

  // boshqaruv paneli
  'dashboard.store.hour.views': 'Real vaqtda tashriflar',
  'dashboard.store.today.views': 'Bugungi tashriflar',
  'dashboard.product.total': 'Jami mahsulot miqdori',
  'dashboard.product.today': 'Yangi bugun',
  'dashboard.order.total': 'Jami buyurtma miqdori',
  'dashboard.sales.total': 'Taxminiy aylanma',
  'dashboard.sales.real': 'Haqiqiy aylanma',
  'dashboard.sales.pay': 'Jami xarajatlar',
  'dashboard.sales.profit': 'Jami foyda',
  'dashboard.commission.total': 'Jami daromad',
  'dashboard.commission.today': 'Bugungi daromad',
  'dashboard.order.sales': 'Mahsulot sotish',
  'dashboard.sales.list': 'Savdo reytingi',
  'dashboard.goods.cate.rate': 'Mahsulot toifalari nisbati',
  'dashboard.goods.wish': 'Sevimli mahsulotlar reytingi',
  'dashboard.january': 'Yanvar',
  'dashboard.february': 'Fevral',
  'dashboard.march': 'mart',
  'dashboard.april': 'Aprel',
  'dashboard.may': 'May',
  'dashboard.june': 'iyun',
  'dashboard.july': 'iyul',
  'dashboard.august': 'Avgust',
  'dashboard.sentember': 'Sentyabr',
  'dashboard.october': 'oktyabr',
  'dashboard.november': 'noyabr',
  'dashboard.december': 'Dekabr',

  // mahsulotlar
  'products.add.new': "Yangi mahsulotlar qo'shish",
  'products.add.from.warehouse': "Ombordan mahsulotlar qo'shish",
  'products.delete': "o'chirish",
  'products.add': "Qo'shish",
  'products.table.img': 'Rasm',
  'products.table.name': 'mahsulot nomi',
  'products.table.category': 'Kategoriya',
  'products.table.wish': 'Yoqdi',
  'products.table.stock': 'aksiya',
  'products.table.price': 'narx',
  'products.table.profit': 'foyda',
  'products.table.action': 'Harakat',
  'products.search.category': "Kategoriya bo'yicha qidirish",
  'products.back.product': "Mahsulotlar ro'yxatiga qaytish",
  'products.total': 'Jami mahsulotlar',
  'products.yes': 'Ha',
  'products.no': 'Bekor qilish',
  'products.batch.add': "To'plam qo'shish",
  'products.ask.add': "Haqiqatan ham mahsulot qo'shmoqchimisiz?",
  'products.batch.delete': "To'plamni o'chirish",
  'products.ask.delete': "Mahsulotni o'chirib tashlamoqchimisiz?",
  'products.top': 'yuqorida',
  'products.syzd': 'Bosh sahifaning yuqori qismi',
  'products.zdwz': 'Yuqori pozitsiya',
  'products.t1': "Rag'batlantirish",
  'products.t2': 'Afzal',
  'products.t3': 'Premium',
  'products.t4': "Yo'q",

  // sharhlar
  'reviews.title': 'Mahsulot sharhlari',
  'reviews.product.name': 'Mahsulot',
  'reviews.user.name': 'foydalanuvchi',
  'reviews.star': 'reyting',
  'reviews.comment': "kontentni ko'rib chiqish",
  'reviews.sku': 'Mahsulot xususiyatlari',
  'reviews.imgs': 'Fotogalereya',
  'reviews.created': 'Sana',

  // do'kon.buyurtma
  'store.order.purchase': 'Hozir sotib ol',
  'store.order.purchase.desc': "Ushbu mahsulotni sotib olish uchun to'lashni xohlaysizmi?",
  'store.order.purchase.yes': "Hozir to'lash",
  'store.order.purchase.no': 'Bekor qilish',
  'store.order.desc': "Bu buyurtmani sotib olish uchun to'lov talab qilinadi va buyurtma bajarilgandan so'ng siz daromad olasiz. ",
  'store.order.no': 'Buyurtma raqami',
  'store.order.number': 'Miqdor',
  'store.order.buyer': 'xaridor',
  'store.order.total': 'Jami summa',
  'store.order.will.earning': 'daromad',
  'store.order.profit': 'foyda',
  'store.order.dividends': 'Dividendlar',
  'store.order.payment.status': "To'lov holati",
  'store.order.seller.buy.status': 'Xarid holati',
  'store.order.status': 'Buyurtma holati',
  'store.order.date': 'Buyurtma sanasi',
  'store.order.purchase.date': 'Xarid sanasi',
  'store.order.action': 'operatsiya',
  'store.order.purchase.amount': 'Xarid summasi',

  //daromad
  'income.create.at': 'yozib olish vaqti',
  'income.order.sn': 'Buyurtma raqami',
  'income.realpay': 'Buyurtma miqdori',
  'daromad.profit': 'daromad',

  //Sozlash
  'setting.avatar': 'Biznes avatar',
  'setting.upload': 'yuklash',
  'setting.shop.name': "do'kon nomi",
  'setting.shop.phone': 'Aloqa raqami',
  'setting.shop.address': "Do'kon manzili",
  'setting.shop.save': 'Saqlash',
  'setting.upload.pic': 'Rasmlarni yuklash',
  'setting.send.pic': 'Rasmlarni yuborish',

  //beseller
  'beseller.title': "Savdogar bo'lish uchun ariza bering",
  'beseller.account': "Hisob ma'lumotlari",
  'beseller.store': "Do'kon ma'lumotlari",
  'beseller.store.name': "do'kon nomi",
  'beseller.store.address': "do'kon manzili",

  // uyni saqlash
  'store.home.title': 'Uy sahifa sozlamalari',
  'store.home.topimg': 'yuqori rasm',
  'store.home.banner': 'Karusel',
  'store.home.up3': 'Izohlar: kamida 3 ta rasm yuklang',
  'store.upload.more': 'Bir nechta rasmni yuklash',
  'store.home.columns': 'ustun',
  'store.home.bgimg': 'Fon rasmi',
  'store.goods.remark': "Izohlar: har bir satrda 5 ta mahsulot, har bir bo'limda maksimal 10 ta mahsulot",
  'store.home.select': 'Mahsulotni tanlang',
  'store.home.add': "Ustun qo'shish",

  //Shaxsiy ma'lumot
  'setting.update.user': "Shaxsiy ma'lumotlar",
  'setting.update.info': "Ma'lumotni o'zgartirish",
  'setting.user.avatar': 'Foydalanuvchi avatar',
  'setting.user.nickname': 'Foydalanuvchi taxallusi',
  'setting.user.nickname.desc': 'Iltimos, foydalanuvchi nikini kiriting',

  'setting.safe.title': "Xavfsizlik ma'lumotlari",
  'setting.user.passwd': "Parolni o'zgartirish",
  'setting.passwd.title1': 'Eski parol',
  'setting.passwd.desc1': 'Iltimos, eski parolni kiriting',
  'setting.passwd.title2': 'Yangi parol',
  'setting.passwd.desc2': 'Yangi parol kiriting',
  'setting.passwd.title3': 'Parolni tasdiqlang',
  'setting.passwd.desc3': 'Tasdiqlash parolini kiriting',
  'setting.invitation': "Do'stlaringizni taklif qiling",
  'setting.copy': 'Nusxa olish',

  'adv.add.products': "Rag'batlantiruvchi mahsulotlarni qo'shish",
  'adv.list.title': 'Mahsulotni reklama qilish',
  'adv.type': "Rag'batlantirish turi",
  'adv.begin.time': "Rag'batlantirish vaqti",
  'adv.end.time': 'Yakunlash vaqti',
  'adv.status': 'status',
  'adv.status.s1': "Targ'ib qilish",
  'adv.status.s2': 'Tugallangan',
  'adv.add.back': "Targ'ib qilinganlar ro'yxatiga qaytish",
  'adv.recharge': "Aksiya ballarini to'ldirish",
  'adv.select.goods': 'Tovarlarni tanlang',
  'adv.select.btn': 'Mahsulotlarni reklama qilish',
  'adv.recharge.title': "Aksiya ballarini to'ldirish",
  'adv.recharge.balance': 'Nuqta balansi',
  'adv.point': 'nuqta',
  'adv.point.rate': 'Bal nisbati',
  'adv.recharge.amount': "To'ldirish miqdori",
  'adv.recharge.input.amount': "To'ldirish miqdorini kiriting",
  'adv.select.mod': "Bo'limni tanlash",
  'adv.mod1': 'Tavsiya etilgan ustunlar',
  'adv.mod2': 'Afzal ustun',
  'adv.mod3': 'Issiq sotiladigan ustunlar',
  'adv.mod4': "Eng yaxshi ro'yxat",
  'adv.mod5': "Kategoriyalar ro'yxati",
  'adv.mod6': 'Tafsilotlarni reklama qilish',
  'adv.ge': 'Miqdor',
  'adv.xs': 'soat',
  'adv.xzbk': "Bo'limni tanlash",
  'adv.syme': 'Qolgan kvota',
  'adv.tgsc': 'Aksiya muddati',
  'adv.tgjg': 'Aksiya narxi',
  'adv.syye': 'Joriy balans',
  'adv.tgye': "Rag'batlantirish balansi",
  'adv.yj1': 'kutilayotgan',
  'adv.yj2': 'Aksiyani boshlash',
  'adv.pay.point': "Iste'mol nuqtalari",

  'wallet.ykcsxf': "Ko'chirish to'lovi yechib olindi",
  'wallet.sxf': "ishlov berish to'lovi",
  'wallet.zxje': 'Minimal miqdor',
  'wallet.24hxe': '24 soatlik chegara',
  'wallet.desc.text': "Mansabdor shaxs hech qanday holatda sizdan ma'lum bir hisob raqamiga pul o'tkazishingizni so'ramaydi va sizdan tasdiqlash kodini ham so'ramaydi. Iltimos, xarid agentlari, pul yuvish, noqonuniy pul yig'ish va boshqa noqonuniy harakatlarda qatnashmang va onlayn firibgarlikdan ehtiyot bo'ling",
  'wallet.cunbi.text': "Faqat bu manzilga {name} depozit qilishingiz mumkin, boshqa aktivlarni qaytarib bo'lmaydi",

  'credit.title': 'Kredit limiti',
  'credit.ed': 'summa',
  'credit.hk': "To'lov",
  'credit.kyye': 'Mavjud balans',
  'credit.dqqk': 'Joriy qarz',
  'credit.hkje': "To'lov miqdori",
  'credit.hkje.desc': "To'lov miqdorini kiriting",
  'credit.cgje': 'Xarid summasi',
  'credit.qrz': 'Autentifikatsiyani bekor qilish',
  'credit.srxyj': "Kredit to'lovi miqdorini kiriting",

  'store.gzl': 'Diqqat',
  'store.wxz': 'Cheksiz',

  'auth.smrz': 'Haqiqiy ism autentifikatsiyasi',
  'auth.rzlx': 'turi',
  'auth.sfzm': 'ID kartaning old tomoni',
  'auth.sffm': 'ID kartaning orqa tomoni',
  'auth.zsxm': 'haqiqiy ism',
  'auth.zjhm': 'ID raqami',
  'auth.yyzz': 'Biznes litsenziyasi',
  'auth.gsmc': 'Kompaniya nomi',
  'auth.shz': "ko'rib chiqilmoqda",
  'auth.ytg': "O'tdi",
  'auth.ybh': 'Rad etilgan',
  'auth.zt': 'status',
  'auth.gr': 'shaxsiy',
  'auth.gs': 'Korxona',
  'auth.ljtj': 'Sertifikatlash uchun ariza',
  'auth.wrz': 'Autentifikatsiya qilinmagan',

  'credit.qts': "Ko'rsatmalarni ko'rish",
  'store.spss': 'Mahsulot chegarasi',
  'store.dpfh': "Do'kon dividend",
  'store.qydj': 'kapital darajasi',
  'store.level': 'daraja',
  'store.jian': 'parchalar',

  'order.notify.email': "E-pochta xavfi nazorati tufayli platforma buyurtma eslatma xatlari tez-tez yuboriladi va bloklanadi, ularni o'z vaqtida olish uchun kirish elektron pochta ro'yxatiga {email} ni qo'shish tavsiya etiladi.",

  'setting.sjxx': "Biznes ma'lumotlari",
  'setting.smrz': 'Haqiqiy ism autentifikatsiyasi',
  'setting.aqzx': 'Xavfsizlik markazi',
  'setting.tzxx': "Ogohlantirish ma'lumotlari",
  'setting.dpzx': 'Bezatish bosh sahifasi',
  'setting.sjqy': 'Savdogar huquqlari',
  'setting.sjdjqy': 'Savdogar darajasidagi huquq va manfaatlarning tavsifi',

  'setting.fhbky': 'Joriy dividend mavjud emas',
  'setting.spgzyzy': "Do'kon qoidalari va ko'rsatmalari",

  'desc.dqqy': 'Joriy kapital',
  'desc.xjqy': 'Quyi huquq va manfaatlar',
  'desc.sjqy_0': "Do'kon kredit limiti",
  'desc.sjqy_1': 'Dividendni saqlash',
  'desc.sjqy_2': "Chiqarilishi mumkin bo'lgan mahsulotlar miqdori",

  'share.yqlb': "taklifnomalar ro'yxati",
  'share.zmj': 'Jami sotuvchi',
  'share.zdd': 'Jami buyurtma',
  'share.zsy': 'Jami daromad',
  'share.wcl': 'Qayta ishlanmagan',
  'share.ycl': 'Qayta ishlangan',
  'share.ywc': 'Tugallandi',
  'share.dpmc': "Do'kon nomi",
  'share.ddl': 'Buyurtma hajmi',
  'share.jjsy': 'Brokerlik daromadi',
  'share.zts': 'Elementlarning umumiy soni',

  'chat.state': 'davlat',
  'chat.zx': 'Onlayn',
  'chat.ljz': 'Ulanish',

  'bind.wallet.title': 'Pulni yechib olish manzili',
  'bind.address.title': "Manzil qo'shish",
  'bind.bj': 'Tahrirlash',
  'bind.sc': "O'chirish",
  'bind.qrsc': "Ushbu manzilni o'chirib tashlamoqchimisiz?",
  'bind.qbdz': 'hamyon manzili',
  'bind.tjsj': "Vaqt qo'shish",
  'bind.cz': 'Operatsiya',
  'withdrawal.address.choice': 'Iltimos, yechib olish manzilini tanlang!',

  'order.sxsj': 'Samarali vaqt',
  'order.ljsj': 'Darhol kuchga kiradi',
  'order.dssj': "Vaqt bo'yicha effekt",

  'store.st.zt': "Do'kon holati",
  'store.st.zc': 'normal',
  'store.st.zc.desc': "Do'koningiz normal, uni saqlashda davom eting",
  'store.st.yc': 'Istisno',
  'store.st.yc.desc': "Do'koningizda buyurtma anormalliklari bor yoki mijozlardan shikoyatlar kelib tushdi",
  'store.st.dj': 'Muzlatish',
  'store.st.dj.desc': "Do'koningiz ma'lum operatsion risklarga ega, bu esa mablag'larning muzlatilishiga olib keladi",
  'store.st.jy': "o'chirilgan",
  'store.st.jy.desc': "Do'koningiz tegishli platforma kelishuvlari va qoidalarini buzgan va o'chirib qo'yilgan",

  'task.sy': 'Uy',
  'task.rwlbo': "Vazifalar ro'yxati",
  'task.rwxqo': 'Vazifa tafsilotlari',
  'task.ljcy': 'Hozir ishtirok et',
  'task.gdrw': "Qo'shimcha vazifalar",
  'task.rwlb': "Buyurtma olish vazifalari ro'yxati",
  'task.qbrw': 'Barcha vazifalar',
  'task.wcyd': 'Men ishtirok etdim',
  'task.qdz': 'Buyurtma bajarilmoqda',
  'task.ywc': 'Bajarildi',
  'task.djy': 'Sotish uchun',
  'task.ddje': 'Buyurtma miqdori',
  'task.cyrs': 'Ishtirokchilar soni',
  'task.ewsy': "Qo'shimcha daromad",
  'task.jzsj': 'Muddati',
  'task.ckxq': "Tafsilotlarni ko'rish",
  'task.rwxq': 'Vazifa tafsilotlari',
  'task.cylb': "Ishtirok etish ro'yxati",
  'task.qdcyrw': "Ushbu buyurtma topshirig'ida ishtirok etishni xohlaysizmi?",
  'task.sd': 'Ha',
  'task.bue': "Yo'q",

  'task.wdsy': 'Daromad',
  'task.wdfh': 'Dividend',
  'task.wdqd': 'Buyurtmalarni olish',
  'task.dd.leixin': 'Buyurtma turi',
  'task.dd.qdrw': 'Buyurtmani olish vazifasi',
  'task.dd.ptdd': 'Oddiy tartib',

  'flow.lltg': "oqimni targ'ib qilish",
  'flow.gmll': 'Trafik paketini sotib olish',
  'flow.dqtc': 'Joriy paket',
  'flow.tc1': 'Paket',
  'flow.sy2': 'qoldiq',
  'flow.tian3': 'dí',
  'flow.jlzs': 'Yozuvlarning umumiy soni',
  'flow.lltc': 'Trafik paketi',
  'flow.ll': 'oqim',
  'flow.gmsj': 'Xarid qilish vaqti',
  'flow.tcjg': 'Paket narxi',
  'flow.sjzf': "Haqiqiy to'lov",
  'flow.tcsm': 'Paket tavsifi',
  'flow.tcsm.sm': "Ushbu paketni xarid qilish do'konning ta'sirini oshiradi va mijozlar trafigini oshiradi. Bundan koʻproq buyurtmalar olish va doʻkon daromadini oshirish uchun foydalaning. ",

  'flow.tcmc': 'Paket nomi',
  'flow.gmsc': 'Xarid muddati',
  'flow.zje': 'Jami summa',
  'flow.jzsj': 'Muddati',
  'flow.gmrq': 'Xarid sanasi',
  'flow.tian1': 'dí',
  'flow.zhou1': 'chjou',
  'flow.yue1': 'oy',

  'share.dj': 'Daraja',
  'share.hz': "O'tkazish",
  'share.hzd': "O'tkazish",
  'share.qbye': 'hamyon balansi',
  'share.tgje': 'Aksiya miqdori',
  'share.hzje': "O'tkazma summasi",
  'share.qsrhzje': "O'tkazma summasini kiriting",

  '_inv._title': 'Taklif tavsifi',
  '_inv._t1': '1.Invite va taklif qilingan munosabatlar',
  '_inv._desc1': "A B ni taklif qiladi, B C ni va C D ni taklif qiladi. Eng yuqori daraja - 4. Agar D yana E ni taklif qilsa, E A ga hech qanday aloqasi bo'lmaydi. ",
  '_inv._t2': '2. Taklif qiluvchi mukofot oladi',
  '_inv._desc2': "A to'ldirilgan B buyurtmasining umumiy miqdoridan 4% oladi, A to'ldirilgan C buyurtmasining umumiy miqdoridan 2% oladi, A bajarilgan D buyurtmasining umumiy miqdoridan 1% oladi",

  '_index._gm': 'Sotib olish',
  '_index._sm': "Ta'rif: Do'kon ta'sirini oshirish va mijozlar trafigini jalb qilish",

  '_n.dlyzc': "Kirish va ro'yxatdan o'tish",
  '_n.hy4': 'Yana 4 tasi bor',
  '_n.ckgd': "Ko'proq ko'rish",
  '_n._cxhzk': 'Aktsiyalar va chegirmalar',
  '_n._cwyp': 'Uy hayvonlari uchun anjomlar',
  '_n._tjsp': 'Maxsus taklif',
  '_n._rmfl': 'Ommabop toifalar',
  '_n._tjsp2': 'Tavsiya etilgan mahsulotlar',
  '_n._rxsp': 'Tavsiya etilgan mahsulotlar',
  '_n._rmsp': 'Mashhur elementlar',
  '_n._gdsp': "Ko'proq mahsulotlar",
  '_n._yzsj': 'Sifatli savdogar',

  '_n._zxsc': 'Onlayn savdo markazi',
  '_n._fkyys': "To'lov va yetkazib berish",
  '_n._xsgz': 'Sotish qoidalari',
  '_n._hhjth': 'Almashtirish va qaytarish',
  '_n._lxwm': "Biz bilan bog'laning",
  '_n._sczc': 'savdo markazi siyosati',
  '_n._tkytj': 'Shartlar va shartlar',
  '_n._tuzc': 'Qaytish siyosati',
  '_n._zczc': "Qo'llab-quvvatlash siyosati",
  '_n._yszc': 'Maxfiylik siyosati',
  '_n._scjj': 'Mall Kirish',
  '_n._ljgm': 'Hozir sotib ol',

  '_n2._rxsp': "Eng ko'p sotiladigan mahsulotlar",
  '_n2._pl': 'Izoh',
  '_n2._sjxx': "Biznes ma'lumotlari",
  '_n2._rhsh': 'Tovarni qanday olish kerak',
  '_n2._xsxd': 'Onlayn buyurtma',
  '_n2._jscldd': "Maxsus mijozlarga xizmat ko'rsatish",
  '_n2._mfps': 'Bepul yetkazib berish',
  '_n2._2xsfh': 'Chaqmoqni yetkazib berish',
  '_n2._1d5tdd': '1-5 kun ichida yetib keldi',
  '_n2._tkzd': 'Tezkor pochta',
  '_n2._ckfh': "Ombordan jo'natish",
  '_n2._zcps': 'Yaqin atrofdagi ombor',
  '_n2._jsd': 'Qabul qilish nuqtasi',
  '_n2._qsjps': "Dunyo bo'ylab yetkazib berish",
  '_n2._thtj': 'Qaytish shartlari',
  '_n2._thtjsm': 'Siz mahsulotni xarid qilingan kundan boshlab 14 kun ichida almashtirishingiz yoki qaytarishingiz mumkin',
  '_n2._gycp': 'Mahsulot haqida',
  '_n2._yc': 'Yashirish',
  '_n2._tiao': 'Tyao',
  '_n2._sypl': 'Barcha sharhlar',
  '_n2._lxfk': 'Fikr-mulohaza qoldiring',
  '_n2._xzspgg': 'Mahsulot spetsifikatsiyasini tanlang',
  '_n2._qxdl': 'Iltimos, avval tizimga kiring',
  '_n2._rnsj': "Ko'rib turganingizdek",
  '_n2._rx': 'Issiq sotuv',

  '_n3._qrdd': 'Buyurtmani tasdiqlash',
  '_n3._njsp': '{num} ta element',
  '_n3._ddzf': "Buyurtma to'lovi",
  '_n3._wydlsp': "Do'konga kirishga xush kelibsiz",

  '_n3._sjwfgm': 'Savdogar tovarlar sotib ololmaydi',
  '_n3._sjbnlt': 'Savdogar suhbatlasha olmaydi',
  '_chat._hc': "O'chirish",

  '_zc._sryxdz': 'Iltimos, elektron pochta manzilingizni kiriting',
  '_zc._yxgscw': 'Elektron pochta formatida xatolik',
  '_zc._qsrmm': 'Parolni kiriting',
  '_zc._lcmmbxd': 'Ikkita parol bir-biriga mos kelmaydi',
  '_zc._hdyzwtg': 'Iltimos, slayder orqali tasdiqlang',
  '_zc._qsryqm': 'Iltimos, taklif kodini kiriting',
  '_zc._yhzh': "Foydalanuvchi hisobini ro'yxatdan o'tkazish",
  '_zc._qzc': "Ro'yxatdan o'tishga o'tish",
  '_zc._srdpm': "Iltimos, do'kon nomini kiriting",

  '_zc._gszz': 'Kompaniya malakasi',
  '_zc._ptzz': 'Platformaga tegishli malakalar',

  '_dd._tijd': 'Buyurtma topshirilmoqda',
  '_dd._xzdz': 'Iltimos, yetkazib berish manzilini tanlang',
  '_dd._zfz': "To'lov davom etmoqda",

  '_wd._zf': "to'lov",
  '_wd._cz': "To'ldirish",
  '_wd._txsq': "O'chirish arizasi",
  '_wd._ddsy': 'Buyurtma daromadi',
  '_wd._cgzf': 'Sotib olish',
  '_wd._txbh': 'Rad qilish',
  '_wd._txtg': 'orqali',
  '_wd._ddtk': 'Buyurtmani qaytarish',
  '_wd._yehz': 'Transfer',
  '_wd._gmll': 'Trafik sotib olish',
  '_wd._zjkc': 'Ijara chegirmasi',
  '_wd._fk': 'Yaxshi',
  '_wd._ns': 'Soliq',
  '_wd._bzj': 'Marja',

  '_xxtz._zntz': 'Sayt haqida bildirishnoma',
  '_xxtz._fh': 'qaytish',
  '_xxtz._jzgd': "Ko'proq yuklash",
  '_xxtz._mysj': "Endi yo'q",

  '_sjsy._jrdd': 'Bugungi buyurtma',
  '_sjsy._rxse': 'Kundalik sotuvlar',

  '_sjsy._xh': 'seriya raqami',

  '_wd._nbjy': 'Ichki transfer',
  '_wd._qsruid': 'Iltimos, oluvchining UID kodini kiriting',
  '_wd._je': 'Miqdor',
  '_wd._qsrhzje': "Iltimos, o'tkazma summasini kiriting",
  '_wd._skr': "To'lovchi",
  '_wd._nbhzsm': "Platforma foydalanuvchilariga ichki o'tkazma, oluvchi ma'lumotlarini diqqat bilan tekshiring",

  '_st._spxlphb': 'Mahsulot sotish reytingi',
  '_st._czsp': 'Qidiruvni tiklash',

  '_st._pljrgwc': "Hammasiga qo'shiling",

  '_sj._yxz': 'Tanlangan',
  '_sj._hjzje': 'Jami summa',
  '_sj._zdcr': 'Avtomatik ravishda depozit qilish',

  '_wd._ddfh': 'Dividend',

  '_dd._csqx': 'Buyurtmani bekor qilish',

  '_tx._yhktx': 'Bankdan yechib olish',
  '_tx._sryhmc': 'Bank nomini kiriting',
  '_tx._srskrmc': 'Iltimos, oluvchi nomini kiriting',
  '_tx._srskzh': "Iltimos, to'lov hisob raqamini kiriting",
  '_tx._sryhluh': 'Iltimos, bank marshrut raqamini kiriting',
  '_tx._sryhdm': 'Iltimos, bank kodini kiriting',
  '_tx._sryhdz': 'Iltimos, bank manzilingizni kiriting',
  '_tx._txje': 'Echib olish summasi',
  '_tx._srtxje': 'Iltimos, yechib olish miqdorini kiriting',
  '_tx._txkc': 'Echib olish uchun komissiya {_sxf}%',

  '_yye._yyye': 'Oylik sotuvlar',
  '_yye._ysy': 'Oylik daromad',

  '_sj._hjzsy': 'Jami daromad',

  '_yhdl._dl': 'Kirish',
  '_yhdl._zc': 'Hisob yaratish',

  '_zdy._24zrkf': 'Biz sizga 24/7 yordam berishga tayyormiz',
  '_zdy._zhsz': 'Hisob sozlamalari',
  '_zdy._mfps': 'Cheklangan vaqt uchun siz tanlangan hududlarda minglab mahsulotlarni 2 ish kunida bepul yetkazib berishingiz mumkin. ',
  '_zdy._gmsp': 'Yangi mahsulotlarni xarid qiling',
  '_zdy._czyh': 'Ajoyib takliflar, sevimli mahsulotlaringizdan rohatlaning',
  '_zdy._xsgw': 'Cheklangan vaqt xarid qilish! Bepul yetkazib berish uchun 12-may kunduzigacha buyurtma bering. Maxsus mashinada eshigingizga yetkazib beriladi! ',
  '_zdy._ssgjc': "Kalit so'z qidiruvini kiriting",

  '_zdy._rmjx': 'Sizning eng yaxshi tanlovlaringiz',

  '_dd._plcz': "To'plamli operatsiya",
  '_dd._plcg': 'ommaviy xarid',
  '_dd._qgxcgd': "Sotib olishingiz kerak bo'lgan buyurtmani tekshiring",
  '_dd._yxddbcg': 'Tanlangan menyu xaridni talab qilmaydi',
  '_dd._qdyplcgm': 'Tanlangan buyurtmani ommaviy xarid qilmoqchimisiz? ,Miqdori:{n}',
  '_dd._qdcg': 'Xaridni tasdiqlash',
  '_dd._qx': 'Bekor qilish',
  '_dd._plcgwc': 'Katta xarid yakunlandi, muvaffaqiyatli: {s}/{t}',
  '_dd._zzcgz': 'Xarid qilish',

  '_st._czhk': 'Saqlangan qiymatni qaytarish',
  '_st._dkje': 'Kredit summasi',
  '_st._dkjl': 'Qarz rekordi',
  '_st._hkfs': "To'lov usuli",
  '_st._hkje': "To'lov miqdori",
  '_st._jrong': 'Mall Finance',
  '_st._ljsq': 'Hozir murojaat qiling',
  '_st._qrshkje': "To'lov miqdorini kiriting",
  '_st._qsrdkje': 'Iltimos, kredit summasini kiriting',
  '_st._shz': "Ko'rib chiqilmoqda",
  '_st._sqdklx': 'qarz',
  '_st._sqhk': "To'lov uchun ariza",
  '_st._sqhklx': "To'lov",
  '_st._sqjk': 'Kredit olish uchun ariza berish',
  '_st._sqlx': 'turi',
  '_st._ybh': 'Rad qilish',
  '_st._yjk': 'qarzga olingan',
  '_st._ytg': 'orqali',
  '_st._zzhk': "To'lanishi kerak",

  '_st._dqed': 'Kredit summasi',
  '_st._ccdked': "Kredit limitidan oshib bo'lmaydi",
  '_st._tjsqz': 'Ariza topshirilmoqda',
  '_st._dklx': 'Qiziqish',
  '_st._dkll': 'Kredit foiz stavkasi: {n}%',
  '_kdts._ts': '{t} kun ochildi',
  '_jltg._tgje': 'Reklama sarmoyasi',

  '_st._yqk': 'Karz muddati',
  '_st._qb': 'Hammasi',

  '_spxx._spbm': 'Mahsulot kodi',

  '_scrz._yyzz': 'Biznes litsenziyasi',

  '_sjhb._sjbt': 'Kupon',
  '_sjhb._kfxd': "Kuponlar ro'yxati",
  '_sjhb._fxlb': "Ro'yxatni almashish",
  '_sjdhb._hbje': 'denominatsiya',
  '_sjdhb._fxzs': 'Aktsiyalarning umumiy soni',
  '_sjdhb._fxcz': 'Operatsiya',
  '_sjdhb._fxyhj': 'Ulashish',
  '_sjdhb._gqsj': 'Muddati tugashi',
  '_sjdhb._lqsj': "Yig'ish vaqti",
  '_sjdhb._lqbm': 'kodlash',
  '_sjdhb._wlq': 'Qabul qilinmadi',
  '_sjdhb._wsy': 'ishlatilmayapti',
  '_sjdhb._ysy': 'ishlatilgan',
  '_sjdhb._ylq': 'Qabul qilindi',
  '_sjdhb._ygq': "Muddati o'tgan",
  '_sjdhb._fuzi': 'Nusxa olish',
  '_sjdhb._lqyhj': 'Kupon',
  '_sjdhb._lqlqyhj': 'Kuponni olish',
  '_sjdhb._yhjbm': 'Kupon kodi',
  '_sjdhb._ljlq': 'Hozir oling'
}
